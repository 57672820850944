.absolute{
    &-top-left{
        position: absolute;
        top: 0;
        @if($rtl==false){
            left: 0;
        }
        @if($rtl==true){
            right: 0;
        }
    }
    &-top-right{
        position: absolute;
        top: 0;
        @if($rtl==false){
            right: 0;
        }
        @if($rtl==true){
            left: 0;
        }
    }
    &-bottom-left{
        position: absolute;
        bottom: 0;
        @if($rtl==false){
            left: 0;
        }
        @if($rtl==true){
            right: 0;
        }
    }
    &-bottom-right{
        position: absolute;
        bottom: 0;
        @if($rtl==false){
            right: 0;
        }
        @if($rtl==true){
            left: 0;
        }
    }
}
.pos-rel {
    @extend .position-relative;
}
.pos-abs {
    @extend .position-absolute;
}