$slick-bg-lighter: $light-400 !default;
$slick-bg-light: $base-300 !default;
$slick-tc-color: $light-600 !default;
$slick-font-size: 24px;
$slick-arrow-border-color:  $border-light;
$slick-arrow-border-hover:  $border-color;

$slick-dots-bg :            $light-300;
$slick-dots-bg-active:      $base-light;

/** Slick Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    @if($rtl==false){
        left: 0;
    }
    @if($rtl==true){
        right: 0;
    }
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    @if($rtl==false){
        float: left;
    }
    @if($rtl==true){
        float: right;
    }
    height: 100%;
    min-height: 1px;
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
    &:focus{
        outline: none;
        border: none;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-dots{
    display: flex !important;
    justify-content: center;
    padding-top: 1.5rem;
    li{
        display: inline-flex;
        padding: 0 6px;
        button{
            background-color: $slick-dots-bg;
            border: none;
            border-radius: 20px;
            text-indent: -99999px;
            height: 8px;
            width: 8px;
            padding: 0;
            overflow: hidden;
            transition: all .4s linear;
            &:focus{
                outline: none;
            }
        }
        &.slick-active{
            button{
                background-color: $slick-dots-bg-active;
            }
        }
    }
}

.slick-next, .slick-prev{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    z-index: 9;
    font-size: $slick-font-size;
    color: $base-light;
    background: $white;
    transition: all .4s linear;
    border: 1px solid $slick-arrow-border-color;
    &:hover{
        color: $base-text;
        background: $lighter;
        border-color: $slick-arrow-border-hover;
        opacity: 1;
    }
    .slick-disabled &{
        cursor: default;
        color: $light-300;
        &:hover{
            color: $light-300;
            background: $white;
            border-color: $slick-arrow-border-color;
        }
    }
}
.slick-next{
    @if($rtl==false){
        right: -10px;
    }
    @if($rtl==true){
        left: -10px;
    }
}
.slick-prev{
    @if($rtl==false){
        left: -10px;
    }
    @if($rtl==true){
        right: -10px;
    }
}